// src/components/LoginPage.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Container, Grid, Link, Paper, TextField, Toolbar, Typography } from '@mui/material';

export const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const adminApiUrl = process.env.REACT_APP_API_URL!

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await fetch(adminApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                window.sessionStorage.setItem('token', data.body.token);
                window.sessionStorage.setItem('user', JSON.stringify(data.body.user));
                navigate('/dashboard');
            } else {
                // Handle error based on status code
                switch (response.status) {
                    case 401:
                        setError('Invalid email or password. Please try again.');
                        break;
                    case 500:
                        setError('An error occurred on our side. Please try again later.');
                        break;
                    case 404:
                        setError('The requested resource could not be found.');
                        break;
                    default:
                        setError('Failed to login. Please try again.');
                }
            }
        } catch (e) {
            // We need to check if e is an instance of Error before accessing e.message
            if (e instanceof Error) {
                setError(e.message);
            } else {
                setError('An error occurred while trying to log in');
            }
        }
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Box component="img" src="/logo.png" alt="Logo" sx={{ maxHeight: '2rem' }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Link href="https://gateway.ferrumnetwork.io/" color="inherit">
                        Gateway
                    </Link>
                    <Link href="https://discord.gg/TfrWeN8Vtq" color="inherit" sx={{ ml: 2 }}>
                        Discord Support
                    </Link>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xs">
                <Paper sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }} elevation={3}>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        sx={{ width: '100%', mt: 1 }}
                        onSubmit={handleLogin}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        {error && <Typography color="error">{error}</Typography>}
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}