// src/components/Dashboard.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Drawer, List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Logo from '../assets/logo.png';
import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';


const useStyles = makeStyles({
    logo: {
        maxWidth: '100%',
    },
});

export const Dashboard = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const user = JSON.parse(window.sessionStorage.getItem('user') || 'null');

    const [openSnapHODL, setOpenSnapHODL] = useState(false);

    const handleClickSnapHODL = () => {
        setOpenSnapHODL(!openSnapHODL);
    };

    // State variable to keep track of authentication status
    const [isAuthenticated, setIsAuthenticated] = React.useState(user !== null);

    // Effect to run when user changes
    React.useEffect(() => {
        setIsAuthenticated(user !== null);

        // If not authenticated, redirect to login
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [user, navigate, isAuthenticated]);

    // Add this to see if Dashboard is rendering
    console.log('Rendering Dashboard', isAuthenticated);


    const handleSignOut = () => {
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('user');
        setIsAuthenticated(false);
    };

    const drawerWidth = 240;

    return isAuthenticated ? (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {/* Logo */}
                    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                        <img src={Logo} alt="Logo" className={classes.logo} />
                    </Box>

                    {/* Welcome message */}
                    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                        <Typography variant="h5">Welcome, {user?.name || 'Guest'}</Typography>
                    </Box>

                    {/* Menu Items */}
                    <List sx={{ flexGrow: 1 }}>
                        <ListItem button onClick={() => navigate('/dashboard')}>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button onClick={handleClickSnapHODL}>
                            <ListItemText primary="SnapHODL" />
                            {openSnapHODL ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openSnapHODL} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/dashboard/new-snaphodl-config')}>
                                    <ListItemText primary="New SnapHODL Config" />
                                </ListItem>
                                <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/dashboard/snaphodl-configs')}>
                                    <ListItemText primary="SnapHODL Configs" />
                                </ListItem>
                                <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/dashboard/active-snaphodls')}>
                                    <ListItemText primary="Active SnapHODLs" />
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>

                    {/* Signout Button */}
                    <Box sx={{ padding: '1rem' }}>
                        <Button variant="contained" onClick={handleSignOut} fullWidth>Sign Out</Button>
                    </Box>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Outlet />
            </Box>
        </Box>
    ) : null;
};
