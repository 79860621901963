// src/components/SnapHODLConfigs.tsx

import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import axios from 'axios';

export const SnapHODLConfigs = () => {
    const [configs, setConfigs] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SNAP_HODL_API_URL}/snapHodlConfig`)
            .then(res => {
                setConfigs(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ verticalAlign: 'top' }}>SnapShot Config Name</TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>Staking Contract Data</TableCell>
                        <TableCell style={{ verticalAlign: 'top' }}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {configs.map((config: any) => (
                        <TableRow key={config._id} style={{ borderBottom: '2px solid #000' }}>
                            <TableCell style={{ verticalAlign: 'top' }}>{config.snapShotConfigName}</TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                                {config.stakingContractData.map((data: any, i: number) => (
                                    <div key={i}>
                                        <p><b>{data.stakingPoolName}</b></p>
                                        <p>{`Staking Contract Address: ${data.stakingContractAddress}`}</p>
                                        <p>{`Staking Pool Type: ${data.stakingPoolType}`}</p>
                                        <p>{`Token Contract Address: ${data.tokenContractAddress}`}</p>
                                        <p>{`Chain ID: ${data.chainId}`}</p>
                                        <p>{`From Block: ${data.fromBlock}`}</p>
                                        <p>{`To Block: ${data.toBlock}`}</p>
                                        <p>{`Block Iteration Size: ${data.blockIterationSize}`}</p>
                                        {i < config.stakingContractData.length - 1 && <hr />}  {/* add a divider line */}
                                    </div>
                                ))}
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                                <Button variant="contained" color={config.isActive ? "primary" : "secondary"}>
                                    {config.isActive ? 'Active' : 'Inactive'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
