// src/components/ActiveSnapHODLsDetails.tsx

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Box } from '@mui/material';
import Papa from 'papaparse';


export const ActiveSnapHODLsDetails = () => {
  const location = useLocation();
  const snapHODL = location.state.snapHODL;

  // Extract the required data from snapHODL
  const { totalStakedBalance, snapShotConfigName, createdAt, updatedAt } = snapHODL;

  // Convert totalStakedBalance from an object to an array of objects
  const totalStakedBalanceData = Object.entries(totalStakedBalance).map(([address, balance]) => ({ address, balance }));

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    };
  
    return new Date(dateString).toLocaleString('en-GB', options);
  };  
  

  const handleExport = () => {
    const csv = Papa.unparse(totalStakedBalanceData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${snapShotConfigName}-${formatDate(updatedAt).replace(/[\s:]/g, '-')}-downloaded-${new Date().toISOString()}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box p={4} sx={{display: 'flex', flexDirection: 'column', width:1}}>
      <Box m={2} sx={{width:1}}>
        <Typography variant="h5">SnapHODL Snapshot Details</Typography>
        <TableContainer component={Paper}>
          <Table aria-label="snapHODL snapshot details table">
            <TableBody>
              <TableRow>
                <TableCell>SnapShot Config Name</TableCell>
                <TableCell>{snapShotConfigName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>{formatDate(createdAt)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Updated At</TableCell>
                <TableCell>{formatDate(updatedAt)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box m={2} sx={{width:1}}>
        <Box sx={{display:'flex', width:1}}>
          <Box mb={0} sx={{ display:'flex', width:1/2, alignContent: 'end' }}>
        <Typography variant="h5" >SnapHODL Balances</Typography>
        </Box>
        <Box pb={1} sx={{ display:'flex', flexDirection:'row-reverse', width:1/2 }}>
        <Button variant="contained" color="primary" onClick={handleExport} >
          Export to CSV
        </Button>  
        </Box>      
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="staked balance table">
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totalStakedBalanceData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>{data.address}</TableCell>
                  <TableCell>{data.balance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      </Box>

      
    </>
  );
};

export default ActiveSnapHODLsDetails;
