// src/App.tsx

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoginPage } from './components/LoginPage';
import { Dashboard } from './components/Dashboard';
import { NewSnapHODLConfig } from './components/NewSnapHODLConfig';
import { SnapHODLConfigs } from './components/SnapHODLConfigs';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { ActiveSnapHODLs } from './components/ActiveSnapHODLs';
import { ActiveSnapHODLsDetails } from './components/ActiveSnapHODLsDetails'



function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* This is for applying baseline CSS to the app */}
      <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="new-snaphodl-config" element={<NewSnapHODLConfig />} />
          <Route path="snaphodl-configs" element={<SnapHODLConfigs />} />
          <Route path="active-snaphodls" element={<ActiveSnapHODLs />} />
            <Route path="active-snaphodls/:id" element={<ActiveSnapHODLsDetails />}/>
        </Route>
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
