import * as yup from 'yup';

export const schema = yup.object().shape({
    snapShotConfigName: yup.string().required('Snapshot Config Name is required'),
    stakingContractData: yup.array().of(
        yup.object().shape({
            stakingPoolName: yup.string().required('Staking Pool Name is required'),
            stakingContractAddress: yup.string().matches(/^(0x)?[0-9a-f]{40}$/i, 'Invalid Ethereum address').required('Staking Contract Address is required'),
            stakingPoolType: yup.string().oneOf(['standard', 'open'], 'Invalid Staking Pool Type').required('Staking Pool Type is required'),
            tokenContractAddress: yup.string().matches(/^(0x)?[0-9a-f]{40}$/i, 'Invalid Ethereum address').required('Token Contract Address is required'),
            chainId: yup.string().required('Chain ID is required'),
            fromBlock: yup.number().positive().integer().required('From Block is required'),
            toBlock: yup.lazy(value => 
                typeof value === 'string'
                ? yup.string().equals(['latest'], 'Must be a number or "latest"').required('To Block is required')
                : yup.number().positive().integer().required('To Block is required')
            ),
            blockIterationSize: yup.number().positive().integer().required('Block Iteration Size is required'),
        })
    ),
});
