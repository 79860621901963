// src/components/NewSnapHODLConfig.tsx
import React from 'react';
import { TextField, Button, Typography, Box, Select, MenuItem } from '@mui/material';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../validationSchemas/SnapHODLConfigSchema';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';

export const NewSnapHODLConfig = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    // Initialize react-hook-form methods
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            snapShotConfigName: '',
            stakingContractData: [
                { stakingPoolName: "", stakingContractAddress: "", stakingPoolType: "", tokenContractAddress: "", chainId: "", fromBlock: "", toBlock: "", blockIterationSize: "" }
            ]
        },
        mode: 'onChange',
    });

    // Initialize useFieldArray methods
    const { fields, append, remove } = useFieldArray({
        control,
        name: "stakingContractData"
    });

    const onSubmit = async (data: any) => {
        data.stakingContractData = data.stakingContractData.map((stakingData: any) => ({
            ...stakingData,
            tokenContractAddress: stakingData.tokenContractAddress.toLowerCase(),
            stakingContractAddress: stakingData.stakingContractAddress.toLowerCase(),
            stakingPoolType: stakingData.stakingPoolType.toLowerCase()
        }));

        // Add isActive: false to the data
        data.isActive = false;

        setIsLoading(true);
        setMessage('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_SNAP_HODL_API_URL}/snapHodlConfig`, data);
            setIsLoading(false);
            setMessage('Configuration added successfully');
        } catch (error) {
            const axiosError = error as AxiosError; // Assert error as AxiosError
            setIsLoading(false);
            if (axiosError.response) {
                const data = axiosError.response.data as { message: string };
                setMessage(data.message);
            } else if (axiosError.request) {
                setMessage('No response received from server');
            } else {
                setMessage('Error occurred while making the request');
            }
        }

        reset();
    };

    return (
        <Box sx={{ width: '70%', maxWidth: '800px', mx: 'auto' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h6">New SnapHODL Config</Typography>
                <Controller
                    name="snapShotConfigName"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            {...field}
                            label="Snap Shot Config Name"
                            variant="outlined"
                            fullWidth
                            error={!!errors.snapShotConfigName}
                            helperText={errors.snapShotConfigName?.message}
                        />
                    }
                />

                {fields.map((field, index) => (
                    <Box key={field.id}>
                        <Controller
                            name={`stakingContractData.${index}.stakingPoolName`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Staking Pool Name"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.stakingPoolName}
                                    helperText={errors.stakingContractData?.[index]?.stakingPoolName?.message}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.stakingContractAddress`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Staking Contract Address"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.stakingContractAddress}
                                    helperText={errors.stakingContractData?.[index]?.stakingContractAddress?.message}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.stakingPoolType`}
                            control={control}
                            render={({ field }) =>
                                <Select
                                    {...field}
                                    label="Staking Pool Type"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.stakingPoolType}
                                // helperText={errors.stakingContractData?.[index]?.stakingPoolType?.message}
                                >
                                    <MenuItem value="open">Open</MenuItem>
                                    <MenuItem value="standard">Standard</MenuItem>
                                </Select>
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.tokenContractAddress`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Token Contract Address"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.tokenContractAddress}
                                    helperText={errors.stakingContractData?.[index]?.tokenContractAddress?.message}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.chainId`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Chain ID"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.chainId}
                                    helperText={errors.stakingContractData?.[index]?.chainId?.message}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.fromBlock`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="From Block"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.fromBlock}
                                    helperText={errors.stakingContractData?.[index]?.fromBlock?.message}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.toBlock`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="To Block"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.toBlock}
                                    helperText={errors.stakingContractData?.[index]?.toBlock?.message}
                                    onChange={event => field.onChange(isNaN(Number(event.target.value)) ? event.target.value : parseInt(event.target.value))}
                                />
                            }
                        />

                        <Controller
                            name={`stakingContractData.${index}.blockIterationSize`}
                            control={control}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label="Block Iteration Size"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.stakingContractData?.[index]?.blockIterationSize}
                                    helperText={errors.stakingContractData?.[index]?.blockIterationSize?.message}
                                />
                            }
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button type="button" onClick={() => remove(index)}>Remove</Button>
                        </Box>
                    </Box>
                ))}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
                    <Button type="button" onClick={() => append({ stakingPoolName: "", stakingContractAddress: "", stakingPoolType: "", tokenContractAddress: "", chainId: "", fromBlock: "", toBlock: "", blockIterationSize: "" })} fullWidth>Add Another Staking Pool</Button>
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>Submit</Button>
                    {isLoading && <p>Loading...</p>}
                    {message && <p>{message}</p>}
                </Box>
            </form>
        </Box>
    );
};
