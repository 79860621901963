// src/components/ActiveSnapHODLs.tsx

import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const ActiveSnapHODLs = () => {
    const [activeSnapHODLs, setActiveSnapHODLs] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SNAP_HODL_API_URL}/getAllSnapShots`)
            .then(res => {
                setActiveSnapHODLs(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const navigate = useNavigate();

    const viewDetails = (snapHODL: any) => {
        console.log(`SnapHODL DATA:`, snapHODL);
        navigate('/dashboard/active-snaphodls/' + snapHODL._id, { state: { snapHODL } });
    };


    return (
        <TableContainer component={Paper}>
            <Table aria-label="active snapHODLs table">
                <TableHead>
                    <TableRow>
                        <TableCell>SnapShot Config Name</TableCell>
                        <TableCell>Staking Contracts Configured</TableCell>
                        <TableCell>Total Unique Stakers</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeSnapHODLs.map((snapHODL: any) => (
                        <TableRow key={snapHODL._id}>
                            <TableCell>{snapHODL.snapShotConfigName}</TableCell>
                            <TableCell>{snapHODL.stakingContractDataBalances.length}</TableCell>
                            <TableCell>{Object.keys(snapHODL.totalStakedBalance).length}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={() => viewDetails(snapHODL)}>
                                    View Details
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
